<template>
  <div class="create_gg_ad">
    <!-- 创建谷歌广告 -->
    <el-row>
      <el-col :span="4">
        <div class="title">流程展示</div>
        <div class="lf_menu_content">
          <ul>
            <div class="first_title">
              <li
                v-for="(item, index) in menuData.slice(0, 2)"
                :key="index"
              >
                <a @click="f_goAnchor('#' + 'id' + item.id)"
                  ><span>{{ index + 1 }}</span
                  >{{ item.name }}</a
                >
                <p v-if="index != 1"></p>
              </li>
            </div>
            <div class="second_title">
              <li
                v-for="(item, index) in menuData.slice(3, -1)"
                :key="index"
              >
                <a @click="f_goAnchor('#' + 'id' + item.id)">{{ item.name }}</a>
              </li>
            </div>
            <div class="first_title">
              <li
                v-for="(item, index) in menuData.slice(-1)"
                :key="index"
              >
                <a @click="f_goAnchor('#' + 'id' + item.id)"><span>3</span>{{ item.name }}</a>
              </li>
            </div>
          </ul>
        </div>
      </el-col>
      <el-col :span="20">
        <div class="rt_ad_content">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleFormAll"
            class="demo-ruleForm"
          >
            <!-- 广告账户配置情况 -->
            <div class="ml-20">
              <span v-if="setupInfo.isSetup">
                <span v-if="setupInfo.ctsCtStatus == 'CONVERSION_TRACKING_MANAGED_BY_SELF'">
                  <i class="el-icon-success color-success mr-5"></i>广告账户已完成配置
                </span>
                <span v-else>
                  <el-tooltip
                    content="转化目标和操作由经理账号进行管理的， API回传转化不生效。请前往经理账户下，在设置-->子账户设置中将Google Ads转化账户设置为客户"
                  >
                    <span><i class="el-icon-warning color-warning mr-5"></i>广告账户已完成配置但未生效</span>
                  </el-tooltip>
                </span>
                <el-button
                  type="text"
                  @click="toggleSetupAccountVisible(true)"
                  class="ml-10"
                  >重新配置</el-button
                >
              </span>
              <span v-else>
                <i class="el-icon-warning color-warning mr-5"></i>广告账户未进行API配置
                <el-button
                  type="text"
                  @click="toggleSetupAccountVisible(true)"
                  >去配置</el-button
                >
              </span>
            </div>
            <!-- part 1 目标商品-->
            <div
              class="part_1"
              id="id1"
            >
              <my-title title="落地商品"></my-title>
            </div>
            <div class="content_gg">
              <el-form-item
                label="落地页"
                prop="promote_url_info.landing_url"
                :rules="{ required: true, message: '落地页不能为空', trigger: ['blur'] }"
              >
                <div class="page_url">
                  <el-input
                    type="textarea"
                    :rows="3"
                    v-model="ruleForm.promote_url_info.landing_url"
                    @change="f_getPromotoUrl(ruleForm.promote_url_info.landing_url)"
                  ></el-input>
                  <p @click="f_openChooseType({ tit: '快速生成推广链接', cla: '', wid: '430px' })">生成推广链接</p>
                </div>
              </el-form-item>
              <el-form-item
                label="商品ID"
                prop="promote_url_info.sale_id"
              >
                <el-input
                  v-model="ruleForm.promote_url_info.sale_id"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item
                label="站点ID"
                prop="promote_url_info.site_id"
              >
                <el-input
                  v-model="ruleForm.promote_url_info.site_id"
                  disabled
                ></el-input>
              </el-form-item>
            </div>
            <!-- part 3 广告投放-->
            <div
              class="part_1"
              id="id3"
            >
              <my-title title="广告投放"></my-title>
            </div>
            <!-- 1 广告目标-->
            <!-- <div class="title_part first_title" id="id4">广告目标</div>
                <div class="target_ad content_gg target_type">
                    <radio-box v-model="ruleForm.adType" :adTypeData="adTypeData" @input="f_inputTargetType" @close="f_close" :isShowBtn="false"></radio-box>
                </div>  -->
            <!-- 2 广告资产-->
            <!-- <div class="title_part" id="id5">广告资产</div>
                <div class="target_ad content_gg">
                    <el-form-item label="广告账户" prop="name">
                        <el-select v-model="ruleForm.value" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div> -->
            <!-- 3 投放区域-->
            <div
              class="title_part tit_heigth_10"
              id="id6"
            >
              投放区域
            </div>
            <div class="content_gg target_ad">
              <el-form-item
                label="地区"
                prop="region"
              >
                <el-select
                  v-model="ruleForm.region"
                  filterable
                  placeholder="请选择"
                  @change="f_getCountry"
                  multiple
                >
                  <el-option
                    v-for="(item, index) in constDataGoogle.defaultCountries"
                    :key="item.criteria_id + item.name_zhcn + index"
                    :label="item.name_zhcn"
                    :value="item.criteria_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="语言"
                prop="language"
              >
                <el-select
                  v-model="ruleForm.language"
                  multiple
                  filterable
                  placeholder="请选择"
                  @change="f_getLanguage"
                >
                  <el-option
                    v-for="(item, index) in constDataGoogle.defaultLanguages"
                    :key="item.id + item.nameZhcn + index"
                    :label="item.nameZhcn"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- 4 受众特征-->
            <div
              class="title_part"
              id="id7"
            >
              受众特征
            </div>
            <audience
              v-model="ruleForm.adgroups[adgroupsIndex].adgroup_criterias"
              @input="f_inputAdgroup"
            ></audience>
            <!-- 5 受众群体-->
            <div
              class="title_part tit_heigth_10"
              id="id8"
            >
              受众群体
            </div>
            <div style="margin: 10px 20px">
              <interest
                v-model="ruleForm.adgroups[adgroupsIndex].adgroup_criterias"
                @input="f_inputAdGroupBrowse"
              ></interest>
            </div>
            <!-- 6 出价预算-->
            <div
              class="title_part tit_heigth"
              id="id9"
            >
              投放预算
            </div>
            <div class="budget_content">
              <!-- 广告系列预算 -->
              <div class="bud_tit">广告系列预算</div>
              <div class="bud_subtit">请为广告系列设置预算，google 将自动管理预算再各个广告组间分配</div>
              <el-radio-group v-model="ruleForm.daily_budget_radio">
                <div class="budget_raido">
                  <el-radio :label="1">日预算</el-radio>
                  <el-input
                    placeholder="请输入内容"
                    type="number"
                    :step="0.01"
                    :precision="2"
                    @blur="f_blurBudget(ruleForm.daily_budget, 'daily_budget')"
                    v-model.number="ruleForm.daily_budget"
                    :min="0.01"
                  >
                    <template slot="append">美元</template>
                  </el-input>
                </div>
              </el-radio-group>
              <!-- 设置目标系列费用 -->
              <div class="bud_tar">
                <span>设置目标系列费用</span>
                <el-switch
                  v-model="ruleForm.bidding_strategy_type"
                  active-value="TARGET_CPA"
                  @change="f_changeBiddingStrategyType"
                  inactive-value="MAXIMIZE_CONVERSIONS"
                >
                </el-switch>
              </div>
              <!-- 广告组出价 -->
              <div v-if="ruleForm.bidding_strategy_type == 'TARGET_CPA'">
                <div class="bud_tit bud_tit_ad">广告组出价</div>
                <div class="bud_subtit">请为广告组设置出价，计费模式默认为：每次转化出多少钱</div>
                <div class="budget_raido">
                  <el-select
                    v-model="ruleForm.target_cpa_radio"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in targetCpData"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    placeholder="请输入内容"
                    type="number"
                    :step="0.01"
                    :precision="2"
                    @blur="f_blurBudget(ruleForm.target_cpa, 'target_cpa')"
                    v-model.number="ruleForm.target_cpa"
                    :min="0.01"
                  >
                    <template slot="append">美元/次</template>
                  </el-input>
                </div>
              </div>
            </div>
            <!-- 7 投放时间-->
            <div
              class="title_part tit_heigth"
              id="id10"
            >
              投放时间
            </div>
            <div class="content_gg">
              <el-form-item
                label="投放时段"
                prop="start_date"
              >
                <el-date-picker
                  v-model="ruleForm.start_date"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择开始日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
                <el-date-picker
                  style="margin-left: 10px"
                  v-model="ruleForm.end_date"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </div>

            <!-- part 2 上传创意-->
            <div
              class="part_1"
              id="id2"
            >
              <my-title title="上传创意"></my-title>
            </div>
            <div class="upload_materials">
              <found-ad
                v-if="ruleForm.advertising_channel_type == 'DISCOVERY'"
                :accountId="accountId"
                :defaultCallToActions="defaultCallToActions"
                v-model="ruleForm.adgroups[adgroupsIndex].ads[adsIndex].discovery_multi_asset_ad_info"
                @input="f_foundAd"
                :isPass="isPass"
                :isCludeArabic="isCludeArabic"
              ></found-ad>
              <video-ad
                v-if="ruleForm.advertising_channel_type == 'VIDEO'"
                :accountId="accountId"
                :defaultCallToActions="defaultCallToActions"
                v-model="ruleForm.adgroups[adgroupsIndex].ads[adsIndex].video_responsive_ad_info"
                @input="f_videoAd"
                :isPass="isPass"
                :isCludeArabic="isCludeArabic"
              ></video-ad>
            </div>
          </el-form>
          <el-row class="create_footer_ad">
            <el-button
              type="primary"
              @click="f_onSubmit"
              >立即创建</el-button
            >
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 选择广告类型 -->
    <my-dialog
      :myDialogObj="visibleChooseType"
      class="chooseDialog"
    >
      <div
        style="padding: 10px"
        v-if="visibleChooseType.title.indexOf('选择广告类型') > -1"
      >
        <radio-box
          v-model="ruleForm.advertising_channel_type"
          :adTypeData="adTypeData"
          @input="f_inputAdType"
          @close="f_close"
          :isShowBtn="true"
        ></radio-box>
      </div>
      <promote-links
        v-if="visibleChooseType.title.indexOf('推广链接') > -1"
        @getLandURL="f_getLandURL"
      ></promote-links>
    </my-dialog>

    <setupAccount
      :visible="setupAccountVisible"
      @toggleVisible="toggleSetupAccountVisible"
      :account_ids="accountId"
      :isOne="true"
      @success="checkAccountSetup"
    ></setupAccount>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapState } from 'vuex';
const gk_language = require('@/api/dataJson/gk_language.json');
const regionData = require('@/api/dataJson/region.json');
import moment from 'moment';
import MyTitle from './components/title.vue';
import MyDialog from './common/myDialog/index.vue';
import radioBox from './components/radioBox.vue';
import foundAd from './components/foundAd.vue';
import VideoAd from './components/VideoAd.vue';
import audience from './components/audience/index.vue';
import interest from './components/interest/index.vue';
import promoteLinks from './components/promoteLinks/index.vue';
import { promotionInfo, createDraftCampaign, checkAccountSetup, getLocation } from '@/api/google/ceateAdGg.js';

import setupAccount from '../accountManagement/components/setupAccount'; // 转化设置

export default {
  components: { MyTitle, MyDialog, radioBox, foundAd, VideoAd, audience, interest, promoteLinks, setupAccount },
  props: {
    accountId: {
      type: [String, Array],
      default: '',
    },
  },
  data() {
    return {
      defaultCallToActions: {}, //选择国家后默认选中的行动号召力选项
      isPass: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 3600 * 1000 * 24 < Date.now();
        },
      },
      switchValue: '',
      visibleChooseType: {
        title: '选择广告类型',
        dialogVisible: false,
        width: '80%',
        top: '10vh',
        class: 'noClose',
      },
      regionData: regionData, //地区
      gk_language: gk_language, //语言
      adgroupsIndex: 0, //当前广告组
      adsIndex: 0, //当前广告组中当前的广告
      ruleForm: {
        region: '',
        language: '',
        advertising_channel_type: 'DISCOVERY', //VIDEO DISCOVERY
        promote_url_info: {
          landing_url: '', //'https://ttadday.com/index/detail.html?sno=Nzc0Mjg2MjI4&coll_id=103212650&',
          sale_id: '',
          site_id: '',
        }, //落地页
        campaign_criterias: [], //系列
        daily_budget: 20.0, //日预算
        daily_budget_radio: 1,
        target_cpa: null, //广告组出价
        target_cpa_radio: '1',
        bidding_strategy_type: 'MAXIMIZE_CONVERSIONS',
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: '',
        adgroups: [
          {
            adgroup_criterias: [], //受众
            ads: [
              {
                discovery_multi_asset_ad_info: {
                  marketing_images: [],
                  square_marketing_images: [],
                  portrait_marketing_images: [],
                  logo_images: [],
                  headlines: [
                    {
                      text: '',
                    },
                  ],
                  descriptions: [
                    {
                      text: '',
                    },
                  ],
                }, //发现广告
                video_responsive_ad_info: {
                  headlines: [
                    {
                      text: '',
                    },
                  ],
                  long_headlines: [
                    {
                      text: '',
                    },
                  ],
                  descriptions: [
                    {
                      text: '',
                    },
                  ],
                  videos: [],
                  companion_banners: [],
                  call_to_actions: [],
                }, //视频广告
                ad_type: '', //写死的数值 VIDEO_RESPONSIVE_AD 视频广告   DISCOVERY_MULTI_ASSET_AD 发现广告
              },
            ],
          },
        ],
      },
      rules: {
        start_date: [{ required: true, message: '请选择开始日期', trigger: ['blur', 'change'] }],
        language: [{ required: true, message: '请选择语言', trigger: ['blur', 'change'] }],
        region: [{ required: true, message: '请选择地区', trigger: ['blur', 'change'] }],
      },
      options: [],
      menuData: [
        //{id:'4',name:'广告目标'},{id:'5',name:'广告资产'},
        { id: '1', name: '目标商品' },
        { id: '3', name: '广告投放' },
        { id: '6', name: '投放区域' },
        { id: '7', name: '受众特征' },
        { id: '8', name: '受众群体' },
        { id: '9', name: '出价预算' },
        { id: '10', name: '投放时间' },
        { id: '2', name: '上传创意' },
      ], //左侧导航
      setupInfo: {}, //账户进行了API配置
      setupAccountVisible: false,
    };
  },
  computed: {
    ...mapState({
      googleValid: (state) => state.initData.googleValid,
      constDataGoogle: (state) => state.initData.constDataGoogle,
      adTypeData: (state) => state.initData.adTypeData,
      targetCpData: (state) => state.initData.targetCpData,
    }),
    isCludeArabic() {
      return this.ruleForm.language.includes(1019);
    },
  },
  watch: {},
  created() {
    this.checkAccountSetup();
  },
  mounted() {
    //   this.f_openChooseType({tit:'选择广告类型',cla:'noClose',wid:'410px'})
    this.f_resetForm('ruleFormAll');
  },
  beforeDestroy() {},
  methods: {
    //   设置目标系列
    f_changeBiddingStrategyType(v) {
      if (this.ruleForm.bidding_strategy_type == 'TARGET_CPA') {
        this.ruleForm.target_cpa = 12.0;
      } else this.ruleForm.target_cpa = null;
    },
    //   清空所有校验
    f_resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //   选中国家 ---默认处理语言和行动号召力
    f_getCountry(val) {
      let language = [];
      if (this.constDataGoogle && this.constDataGoogle.defaultCountries.length > 0) {
        let defaultCallToActions;
        this.constDataGoogle.defaultCountries.map((countrie) => {
          if (val.includes(countrie.criteria_id)) {
            language.push(...countrie.defaultLanguageIds);
            if (!defaultCallToActions) {
              defaultCallToActions = countrie.defaultCallToAction;
            }
          }
        });

        this.ruleForm.language = Array.from(new Set(language));
        this.defaultCallToActions = defaultCallToActions;
        language = [];
        // 处理选择的语言
        if (this.ruleForm.language.length > 0)
          this.ruleForm.language.map((item) => {
            let obj = { type: 'LANGUAGE', criterion_id: '' };
            obj.criterion_id = item;
            language.push(obj);
          });
      }
      // 处理选择的地区格式
      let region = [];
      if (this.ruleForm.region && this.ruleForm.region.length > 0) {
        this.ruleForm.region.forEach((item) => {
          region.push({ type: 'LOCATION', criterion_id: item });
        });
      }
      this.ruleForm.campaign_criterias = [...language, ...region];
    },
    // 选中语言
    f_getLanguage(val) {
      let region = this.ruleForm.campaign_criterias.filter((item) => item.type == 'LOCATION'),
        language = [];
      if (val.length > 0)
        val.map((item) => {
          let obj = { type: 'LANGUAGE', criterion_id: '' };
          obj.criterion_id = item;
          language.push(obj);
        });
      this.ruleForm.campaign_criterias = [];
      this.ruleForm.campaign_criterias = [...language, ...region];
      console.log('-------language1', language);
      console.log('-------region1', region);
    },

    //   获取落地页的商品id和站点id
    f_getPromotoUrl(url) {
      console.log(url, 'url');
      if (!url) {
        this.$nextTick(() => {
          this.ruleForm.promote_url_info.sale_id = '';
          this.ruleForm.promote_url_info.site_id = '';
        });
        return;
      }
      this.f_getPromotionInfo(url);
    },
    f_getPromotionInfo: debounce(function (url) {
      this.$showLoading();
      promotionInfo({ url: url })
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$nextTick(() => {
              this.ruleForm.promote_url_info.sale_id = res.data.sale.saleId;
              this.ruleForm.promote_url_info.site_id = res.data.site.id;
              this.getLocation();
            });
          }
        })
        .catch((error) => {
          this.$hideLoading();
          return this.$message.warning('不可用的链接！');
        });
    }, 200),
    // 获取站点对应的地区
    getLocation() {
      let params = {
        siteId: this.ruleForm.promote_url_info.site_id,
      };
      getLocation(params).then((res) => {
        if (res.code == 0) {
          const list = res.data.map((item) => item.criteria_id);
          console.log('list', list);
          this.$set(this.ruleForm, 'region', list);
          this.f_getCountry(list);
        }
      });
    },
    //   生成落地页链接
    f_getLandURL({ landing_url, sale_id, site_id }) {
      this.ruleForm.promote_url_info = { landing_url, sale_id, site_id };
      this.visibleChooseType.dialogVisible = false;
    },
    //   提交表单
    f_onSubmit() {
      this.isPass = !this.isPass;
      this.$refs.ruleFormAll.validate((valid) => {
        if (!valid) {
          this.$firstInputFocus();
          return false;
        }
        setTimeout(async () => {
          if (!this.googleValid) return false;
          let param = JSON.parse(JSON.stringify({ ...this.ruleForm, account_id: this.accountId.toString() }));
          param.adgroups.forEach((adg) => {
            adg.ads.map((v) => {
              if (v.ad_type == 'VIDEO_RESPONSIVE_AD')
                return { ad_type: 'VIDEO_RESPONSIVE_AD', video_responsive_ad_info: v.video_responsive_ad_info };
              else if (v.ad_type == 'DISCOVERY_MULTI_ASSET_AD')
                return {
                  ad_type: 'DISCOVERY_MULTI_ASSET_AD',
                  discovery_multi_asset_ad_info: v.discovery_multi_asset_ad_info,
                };
              else {
                let errMsg = '不支持 ' + v.ad_type;
                this.$message.error(errMsg);
                throw new Error(errMsg);
              }
            });
          });
          if (!this.setupInfo.isSetup) {
            this.$confirm('广告账户未进行API配置，是否配置后在继续?', '账户配置验证', {
              confirmButtonText: '配置',
              cancelButtonText: '继续',
              type: 'warning',
            })
              .then(() => {
                this.toggleSetupAccountVisible(true);
              })
              .catch(async () => {
                this.createDraftCampaign(param);
              });
          } else {
            this.createDraftCampaign(param);
          }
        }, 500);
      });
    },
    //   创建
    async createDraftCampaign(param) {
      this.$showLoading();
      let res = await createDraftCampaign(param);
      this.$hideLoading();
      if (res.code == 0) {
        this.$message.success('创建广告成功！');
        setTimeout(() => {
          this.$emit('closeCreate');
          this.$emit('success');
        }, 2000);
      }
    },
    //   预算的最小值
    f_blurBudget(val, name) {
      if (val < 0.01) this.ruleForm[name] = 0.01;
    },
    //   受众特征
    f_inputAdgroup(data) {
      // 分离出来受众浏览的数据来源
      // let USER_INTEREST = this.ruleForm.adgroup_criterias.filter(item=>item.type == 'USER_INTEREST')
      // 合并受众浏览和受众特征的数据
      this.ruleForm.adgroups[this.adgroupsIndex].adgroup_criterias = [...data];
    },
    // 受众浏览
    f_inputAdGroupBrowse(data) {
      // 分离出来受众特征的数据
      // let array = this.ruleForm.adgroup_criterias.filter(item=>item.type != 'USER_INTEREST')
      // 合并受众浏览和受众特征的数据
      this.ruleForm.adgroups[this.adgroupsIndex].adgroup_criterias = [...data];
    },
    // 选择广告类型
    f_inputAdType(val) {
      this.ruleForm.advertising_channel_type = val;
      this.$emit('changeAdTitles', val == 'VIDEO' ? '视频' : '发现');
    },
    f_inputTargetType(val) {
      this.ruleForm.advertising_channel_type = val;
    },
    // 发现广告
    f_foundAd(data) {
      this.ruleForm.adgroups[this.adgroupsIndex].ads[this.adsIndex].discovery_multi_asset_ad_info = { ...data };
      this.ruleForm.adgroups[this.adgroupsIndex].ads[this.adsIndex].ad_type = 'DISCOVERY_MULTI_ASSET_AD';
    },
    // 视频广告
    f_videoAd(data) {
      this.ruleForm.adgroups[this.adgroupsIndex].ads[this.adsIndex].video_responsive_ad_info = { ...data };
      this.ruleForm.adgroups[this.adgroupsIndex].ads[this.adsIndex].ad_type = 'VIDEO_RESPONSIVE_AD';
    },
    // 关闭弹窗
    f_openChooseType({ tit, cla, wid }) {
      this.visibleChooseType.title = tit;
      this.visibleChooseType.class = cla;
      this.visibleChooseType.width = wid;
      this.visibleChooseType.dialogVisible = true;
    },
    // 关闭弹窗
    f_close() {
      this.visibleChooseType.dialogVisible = false;
    },
    //模拟锚点跳转
    f_goAnchor(selector) {
      //参数selector是id选择器（#anchor14）
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth',
      });
    },
    // 获取当前账户API配置情况
    checkAccountSetup() {
      let params = {
        accountId: this.accountId[0],
      };
      checkAccountSetup(params).then((res) => {
        if (res.code == 0) {
          this.setupInfo = res.data;
        }
      });
    },
    toggleSetupAccountVisible(bool) {
      this.setupAccountVisible = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/google/googleCreate.scss';

.region_gg {
  display: flex;
  justify-content: flex-start;
  ::v-deep.el-form-item {
    width: 50%;
    .el-select {
      width: 100%;
    }
    & + .el-form-item {
      margin-left: 10px;
    }
  }
}
</style>
